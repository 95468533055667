import React from "react";
import {
  Text,
  Select,
  Space,
} from '@mantine/core';
import { Controller } from 'react-hook-form';
import { MantineProvider } from '@mantine/core';

const SelectInputRef = ({
  name,
  data,
  label,
  errors,
  control,
  required,
  disabled,
  subText,
}) => {
  return (
    <MantineProvider>
      <Controller
        name={name}
        control={control}
        rules={{
          required: required ?? true
        }}
        disabled={disabled ?? false}
        render={({field}) => (
          <Select
            label={label}
            data={data}
            {...field}
          />
        )}
      />
      {errors?.[name]?.type === "required" && (
        <Text
          size="sm"
          color="red"
        >
          This field is required
        </Text>
      )}
      {subText &&
        <Text
          size="xs"
          fs="italic"
        >
          {subText}
        </Text>
      }
      <Space h="md"/>
    </MantineProvider>
  )
}

export default SelectInputRef;
